<template>
  <div class="container-fluid">
    <div class="flex-column">
      <div class="card">
        <div class="d-flex d-print-none align-items-center justify-content-end mb-6">
          <!--          <px-dropdown-->
          <!--              v-if="shared"-->
          <div
            class="btn btn-icon btn-light btn-active-light-primary me-4"
            data-fc-fullscreen-toggler
            @click="toggleFullScreen"
          >
            <i class="bi bi-fullscreen" />
          </div>
          <px-dropdown
            v-if="pdfFile"
            title="Actions"
            btn-classes="btn btn-sm btn-primary dropdown-toggle"
            :is-icon-hidden="true"
            :items="
              emptyPrice
                ? [
                    {
                      title: 'Create new Version',
                      disabled: createNewProposalBasedOnCurrentInProcess,
                      inProcess: createNewProposalBasedOnCurrentInProcess,
                      action: () => createNewProposalBasedOnCurrent(itemId),
                    },
                    { title: 'Share', action: openModal },
                    {
                      title: 'Generate PDF',
                      disabled: isPdfInProcess,
                      inProcess: isPdfInProcess,
                      action: makeAndDownloadPdf,
                    },
                    { title: 'Print', disabled: true, action: printContract },
                    {
                      title: 'Download',
                      disabled: true,
                      action: () => downloadFile(pdfFile),
                    },
                  ]
                : [
                    {
                      title: 'Create new Version',
                      disabled: createNewProposalBasedOnCurrentInProcess,
                      inProcess: createNewProposalBasedOnCurrentInProcess,
                      action: () => createNewProposalBasedOnCurrent(itemId),
                    },
                    { title: 'Share', action: openModal },
                    {
                      title: 'Generate PDF',
                      disabled: isPdfInProcess,
                      inProcess: isPdfInProcess,
                      action: makeAndDownloadPdf,
                    },
                    {
                      title: sentToSign ? 'Resend for Sign' : 'Send for Sign',
                      disabled: subTotal <= 0 || isPdfAndSendForSignInProcess || status === 'Signed',
                      inProcess: isPdfAndSendForSignInProcess,
                      action: sendForSign,
                    },
                    { title: 'Print', disabled: true, action: printContract },
                    {
                      title: 'Download',
                      disabled: true,
                      action: () => downloadFile(pdfFile),
                    },
                  ]
            "
          />
          <px-dropdown
            v-else
            title="Actions"
            btn-classes="btn btn-sm btn-primary dropdown-toggle"
            :is-icon-hidden="true"
            :items="
              emptyPrice
                ? [
                    { title: 'Share', action: openModal },
                    {
                      title: 'Generate PDF',
                      disabled: isPdfInProcess,
                      inProcess: isPdfInProcess,
                      action: makeAndDownloadPdf,
                    },
                    { title: 'Print', disabled: true, action: printContract },
                    {
                      title: 'Download',
                      disabled: true,
                      action: () => downloadFile(pdfFile),
                    },
                  ]
                : [
                    { title: 'Share', action: openModal },
                    {
                      title: 'Generate PDF',
                      disabled: isPdfInProcess,
                      inProcess: isPdfInProcess,
                      action: makeAndDownloadPdf,
                    },
                    {
                      title: sentToSign ? 'Resend for Sign' : 'Send for Sign',
                      disabled: subTotal <= 0 || isPdfAndSendForSignInProcess || status === 'Signed',
                      inProcess: isPdfAndSendForSignInProcess,
                      action: sendForSign,
                    },
                    { title: 'Print', disabled: true, action: printContract },
                    {
                      title: 'Download',
                      disabled: true,
                      action: () => downloadFile(pdfFile),
                    },
                  ]
            "
          />
        </div>
        <!--        <pxs-proposal-preview :proposal-status="status" />-->
        <pxs-proposal-preview :is-full-screen="isFullScreen">
          <template #before-header>
            <div v-show="isFullScreen" class="card-header justify-content-end align-items-center py-3">
              <div
                class="btn btn-icon btn-light btn-active-light-primary me-4"
                data-fc-fullscreen-toggler
                @click="toggleFullScreen"
              >
                <i class="bi bi-fullscreen-exit" />
              </div>

              <px-dropdown
                v-if="pdfFile"
                title="Actions"
                btn-classes="btn btn-sm btn-primary dropdown-toggle"
                :is-icon-hidden="true"
                :items="
                  emptyPrice
                    ? [
                        {
                          title: 'Create new Version',
                          disabled: createNewProposalBasedOnCurrentInProcess,
                          inProcess: createNewProposalBasedOnCurrentInProcess,
                          action: () => createNewProposalBasedOnCurrent(itemId),
                        },
                        { title: 'Share', action: openModal },
                        {
                          title: 'Generate PDF',
                          disabled: isPdfInProcess,
                          inProcess: isPdfInProcess,
                          action: makeAndDownloadPdf,
                        },
                        { title: 'Print', disabled: true, action: printContract },
                        {
                          title: 'Download',
                          disabled: true,
                          action: () => downloadFile(pdfFile),
                        },
                      ]
                    : [
                        {
                          title: 'Create new Version',
                          disabled: createNewProposalBasedOnCurrentInProcess,
                          inProcess: createNewProposalBasedOnCurrentInProcess,
                          action: () => createNewProposalBasedOnCurrent(itemId),
                        },
                        { title: 'Share', action: openModal },
                        {
                          title: 'Generate PDF',
                          disabled: isPdfInProcess,
                          inProcess: isPdfInProcess,
                          action: makeAndDownloadPdf,
                        },
                        {
                          title: sentToSign ? 'Resend for Sign' : 'Send for Sign',
                          disabled: subTotal <= 0 || isPdfAndSendForSignInProcess || status === 'Signed',
                          inProcess: isPdfAndSendForSignInProcess,
                          action: sendForSign,
                        },
                        { title: 'Print', disabled: true, action: printContract },
                        {
                          title: 'Download',
                          disabled: true,
                          action: () => downloadFile(pdfFile),
                        },
                      ]
                "
              />
              <px-dropdown
                v-else
                title="Actions"
                btn-classes="btn btn-sm btn-primary dropdown-toggle"
                :is-icon-hidden="true"
                :items="
                  emptyPrice
                    ? [
                        { title: 'Share', action: openModal },
                        {
                          title: 'Generate PDF',
                          disabled: isPdfInProcess,
                          inProcess: isPdfInProcess,
                          action: makeAndDownloadPdf,
                        },
                        { title: 'Print', disabled: true, action: printContract },
                        {
                          title: 'Download',
                          disabled: true,
                          action: () => downloadFile(pdfFile),
                        },
                      ]
                    : [
                        { title: 'Share', action: openModal },
                        {
                          title: 'Generate PDF',
                          disabled: isPdfInProcess,
                          inProcess: isPdfInProcess,
                          action: makeAndDownloadPdf,
                        },
                        {
                          title: sentToSign ? 'Resend for Sign' : 'Send for Sign',
                          disabled: subTotal <= 0 || isPdfAndSendForSignInProcess || status === 'Signed',
                          inProcess: isPdfAndSendForSignInProcess,
                          action: sendForSign,
                        },
                        { title: 'Print', disabled: true, action: printContract },
                        {
                          title: 'Download',
                          disabled: true,
                          action: () => downloadFile(pdfFile),
                        },
                      ]
                "
              />
            </div>
          </template>
        </pxs-proposal-preview>
      </div>

      <div class="row">
        <div class="col-md-auto">
          <div class="w-md-300px" />
        </div>
        <div class="col-md">
          <!--begin::Actions-->
          <div class="d-flex flex-stack mt-8 pt-8 border-top">
            <!--begin::Wrapper-->
            <div class="me-4">
              <px-btn color="secondary" :in-process="prevInProcess" :disabled="prevInProcess" @click.native="goToPrev">
                Back
              </px-btn>
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div class="ms-auto">
              <px-btn
                extended-classes="me-2"
                :in-process="finishInProcess"
                :disabled="finishInProcess"
                @click.native="onFinish"
              >
                Finish
              </px-btn>
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Actions-->
        </div>
      </div>
    </div>

    <px-new-modal modal-size="mw-650px" :is-visible="isShareModalOpened" :close-modal="closeModal">
      <template #title>
        <h3>Share</h3>
      </template>
      <template #content>
        <pxm-share :item-id="itemId" @onClose="closeModal" />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

import PxsProposalPreview from '@/sections/PxsProposalPreview';

import PxmShare from '@/modals/PxmShare';

export default {
  mixins: [ProposalWizardMixin],
  data() {
    return {
      isShareModalOpened: false,
      isPdfInProcess: false,
      isPdfAndSendForSignInProcess: false,

      finishInProcess: false,

      createNewProposalBasedOnCurrentInProcess: false,

      isFullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      pdfFile: 'proposalWizard/pdfFile',
      pdfReady: 'proposalWizard/pdfReady',
      shared: 'proposalWizard/shared',
      sentToSign: 'proposalWizard/sentToSign',
      status: 'proposalWizard/status',
      emptyPrice: 'proposalWizard/emptyPrice',
      subTotal: 'proposalWizard/subTotal',

      proposalsMessage: 'proposals/message',
      showEquipments: 'proposals/showEquipments',
      showInclusions: 'proposals/showInclusions',
      showIntroduction: 'proposals/showIntroduction',
      showPaymentDetails: 'proposals/showPaymentDetails',
      showPricing: 'proposals/showPricing',
      showSpecialNotes: 'proposals/showSpecialNotes',
      showSpecifications: 'proposals/showSpecifications',
      showTerms: 'proposals/showTerms',
    }),
  },
  components: { PxmShare, PxsProposalPreview },
  methods: {
    ...mapActions({
      actionCreateNewVersion: 'proposals/createNewVersion',
      actionProposalWizardMakePdf: 'proposalWizard/makePdf',
      actionProposalWizardSendForSign: 'proposalWizard/sendForSign',
      actionUpdate: 'proposals/update',
    }),
    ...mapMutations({
      mutationSetPdfFile: 'proposalWizard/SET_PDF_FILE',
      mutationSetPdfReady: 'proposalWizard/SET_PDF_READY',
      mutationSetSentToSign: 'proposalWizard/SET_SENT_TO_SIGN',

      // mutationSetStatus: 'proposalWizard/SET_STATUS',
      //
      // mutationShowEquipments: 'proposals/SET_SHOW_EQUIPMENTS',
      // mutationShowInclusions: 'proposals/SET_SHOW_INCLUSIONS',
      // mutationShowIntroduction: 'proposals/SET_SHOW_INTRODUCTION',
      // mutationShowPaymentDetails: 'proposals/SET_SHOW_PAYMENT_DETAILS',
      // mutationShowPricing: 'proposals/SET_SHOW_PRICING',
      // mutationShowPlantings: 'proposals/SET_SHOW_PLANTINGS',
      // mutationShowSpecialNotes: 'proposals/SET_SHOW_SPECIAL_NOTES',
      // mutationShowSpecifications: 'proposals/SET_SHOW_SPECIFICATIONS',
      // mutationShowTerms: 'proposals/SET_SHOW_TERMS',
    }),
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    printContract() {
      window.print();
    },
    async onFinish() {
      this.finishInProcess = true;

      await this.actionUpdate({
        id: this.itemId,
        show_equipments: this.showEquipments,
        show_inclusions: this.showInclusions,
        show_introduction: this.showIntroduction,
        show_payment_details: this.showPaymentDetails,
        show_pricing: this.showPricing,
        show_special_notes: this.showSpecialNotes,
        show_specifications: this.showSpecifications,
        show_terms: this.showTerms,
      });

      this.finishInProcess = false;

      await this.v_toast(this.proposalsMessage?.status, 'Updated successfully!');
    },
    goToWizard(id) {
      this.$router.push({ name: 'proposal-wizard', params: { id } }).then(() => {
        this.$router.go(this.$router.currentRoute);
      });
    },
    async createNewProposalBasedOnCurrent(id) {
      this.createNewProposalBasedOnCurrentInProcess = true;

      const newProposalVersion = await this.actionCreateNewVersion(id);
      const newProposalVersionId = newProposalVersion.data.data.id;

      this.createNewProposalBasedOnCurrentInProcess = false;

      this.goToWizard(newProposalVersionId);
    },
    async makePdf() {
      this.isPdfInProcess = true;

      const newPdf = await this.actionProposalWizardMakePdf(this.itemId);
      await this.mutationSetPdfFile(newPdf.data.pdf_file);
      await this.mutationSetPdfReady(true);

      await this.v_toast(this.message?.status, 'Sent successfully!', () => {
        this.isPdfInProcess = false;
      });
    },
    async makeAndDownloadPdf() {
      await this.makePdf();
      await this.downloadFile(this.pdfFile);
    },
    async sendForSign() {
      this.isPdfAndSendForSignInProcess = true;

      try {
        const newPdf = await this.actionProposalWizardMakePdf(this.itemId);
        await this.mutationSetPdfFile(newPdf.data.pdf_file);
        await this.mutationSetPdfReady(true);
        await this.mutationSetSentToSign(true);

        // this.mutationShowEquipments(true);
        // this.mutationShowInclusions(true);
        // this.mutationShowIntroduction(true);
        // this.mutationShowPaymentDetails(true);
        // this.mutationShowPricing(true);
        // this.mutationShowPlantings(true);
        // this.mutationShowSpecialNotes(true);
        // this.mutationShowSpecifications(true);
        // this.mutationShowTerms(true);
        //
        // await this.mutationSetStatus('Sent to Sign');
        await this.actionProposalWizardSendForSign(this.itemId);
        await this.downloadFile(this.pdfFile);
        await this.v_toast(this.message?.status, 'Sent successfully!', () => {
          this.isPdfAndSendForSignInProcess = false;
        });
      } catch {
        this.isPdfAndSendForSignInProcess = false;
      }
    },
    downloadFile(url) {
      this.downloadPdfFromTab(url, 'proposal');
    },
    openModal() {
      this.isShareModalOpened = true;
    },
    closeModal() {
      this.isShareModalOpened = false;
    },
  },
};
</script>
