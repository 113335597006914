var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"d-flex d-print-none align-items-center justify-content-end mb-6"},[_c('div',{staticClass:"btn btn-icon btn-light btn-active-light-primary me-4",attrs:{"data-fc-fullscreen-toggler":""},on:{"click":_vm.toggleFullScreen}},[_c('i',{staticClass:"bi bi-fullscreen"})]),(_vm.pdfFile)?_c('px-dropdown',{attrs:{"title":"Actions","btn-classes":"btn btn-sm btn-primary dropdown-toggle","is-icon-hidden":true,"items":_vm.emptyPrice
              ? [
                  {
                    title: 'Create new Version',
                    disabled: _vm.createNewProposalBasedOnCurrentInProcess,
                    inProcess: _vm.createNewProposalBasedOnCurrentInProcess,
                    action: function () { return _vm.createNewProposalBasedOnCurrent(_vm.itemId); },
                  },
                  { title: 'Share', action: _vm.openModal },
                  {
                    title: 'Generate PDF',
                    disabled: _vm.isPdfInProcess,
                    inProcess: _vm.isPdfInProcess,
                    action: _vm.makeAndDownloadPdf,
                  },
                  { title: 'Print', disabled: true, action: _vm.printContract },
                  {
                    title: 'Download',
                    disabled: true,
                    action: function () { return _vm.downloadFile(_vm.pdfFile); },
                  } ]
              : [
                  {
                    title: 'Create new Version',
                    disabled: _vm.createNewProposalBasedOnCurrentInProcess,
                    inProcess: _vm.createNewProposalBasedOnCurrentInProcess,
                    action: function () { return _vm.createNewProposalBasedOnCurrent(_vm.itemId); },
                  },
                  { title: 'Share', action: _vm.openModal },
                  {
                    title: 'Generate PDF',
                    disabled: _vm.isPdfInProcess,
                    inProcess: _vm.isPdfInProcess,
                    action: _vm.makeAndDownloadPdf,
                  },
                  {
                    title: _vm.sentToSign ? 'Resend for Sign' : 'Send for Sign',
                    disabled: _vm.subTotal <= 0 || _vm.isPdfAndSendForSignInProcess || _vm.status === 'Signed',
                    inProcess: _vm.isPdfAndSendForSignInProcess,
                    action: _vm.sendForSign,
                  },
                  { title: 'Print', disabled: true, action: _vm.printContract },
                  {
                    title: 'Download',
                    disabled: true,
                    action: function () { return _vm.downloadFile(_vm.pdfFile); },
                  } ]}}):_c('px-dropdown',{attrs:{"title":"Actions","btn-classes":"btn btn-sm btn-primary dropdown-toggle","is-icon-hidden":true,"items":_vm.emptyPrice
              ? [
                  { title: 'Share', action: _vm.openModal },
                  {
                    title: 'Generate PDF',
                    disabled: _vm.isPdfInProcess,
                    inProcess: _vm.isPdfInProcess,
                    action: _vm.makeAndDownloadPdf,
                  },
                  { title: 'Print', disabled: true, action: _vm.printContract },
                  {
                    title: 'Download',
                    disabled: true,
                    action: function () { return _vm.downloadFile(_vm.pdfFile); },
                  } ]
              : [
                  { title: 'Share', action: _vm.openModal },
                  {
                    title: 'Generate PDF',
                    disabled: _vm.isPdfInProcess,
                    inProcess: _vm.isPdfInProcess,
                    action: _vm.makeAndDownloadPdf,
                  },
                  {
                    title: _vm.sentToSign ? 'Resend for Sign' : 'Send for Sign',
                    disabled: _vm.subTotal <= 0 || _vm.isPdfAndSendForSignInProcess || _vm.status === 'Signed',
                    inProcess: _vm.isPdfAndSendForSignInProcess,
                    action: _vm.sendForSign,
                  },
                  { title: 'Print', disabled: true, action: _vm.printContract },
                  {
                    title: 'Download',
                    disabled: true,
                    action: function () { return _vm.downloadFile(_vm.pdfFile); },
                  } ]}})],1),_c('pxs-proposal-preview',{attrs:{"is-full-screen":_vm.isFullScreen},scopedSlots:_vm._u([{key:"before-header",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFullScreen),expression:"isFullScreen"}],staticClass:"card-header justify-content-end align-items-center py-3"},[_c('div',{staticClass:"btn btn-icon btn-light btn-active-light-primary me-4",attrs:{"data-fc-fullscreen-toggler":""},on:{"click":_vm.toggleFullScreen}},[_c('i',{staticClass:"bi bi-fullscreen-exit"})]),(_vm.pdfFile)?_c('px-dropdown',{attrs:{"title":"Actions","btn-classes":"btn btn-sm btn-primary dropdown-toggle","is-icon-hidden":true,"items":_vm.emptyPrice
                  ? [
                      {
                        title: 'Create new Version',
                        disabled: _vm.createNewProposalBasedOnCurrentInProcess,
                        inProcess: _vm.createNewProposalBasedOnCurrentInProcess,
                        action: function () { return _vm.createNewProposalBasedOnCurrent(_vm.itemId); },
                      },
                      { title: 'Share', action: _vm.openModal },
                      {
                        title: 'Generate PDF',
                        disabled: _vm.isPdfInProcess,
                        inProcess: _vm.isPdfInProcess,
                        action: _vm.makeAndDownloadPdf,
                      },
                      { title: 'Print', disabled: true, action: _vm.printContract },
                      {
                        title: 'Download',
                        disabled: true,
                        action: function () { return _vm.downloadFile(_vm.pdfFile); },
                      } ]
                  : [
                      {
                        title: 'Create new Version',
                        disabled: _vm.createNewProposalBasedOnCurrentInProcess,
                        inProcess: _vm.createNewProposalBasedOnCurrentInProcess,
                        action: function () { return _vm.createNewProposalBasedOnCurrent(_vm.itemId); },
                      },
                      { title: 'Share', action: _vm.openModal },
                      {
                        title: 'Generate PDF',
                        disabled: _vm.isPdfInProcess,
                        inProcess: _vm.isPdfInProcess,
                        action: _vm.makeAndDownloadPdf,
                      },
                      {
                        title: _vm.sentToSign ? 'Resend for Sign' : 'Send for Sign',
                        disabled: _vm.subTotal <= 0 || _vm.isPdfAndSendForSignInProcess || _vm.status === 'Signed',
                        inProcess: _vm.isPdfAndSendForSignInProcess,
                        action: _vm.sendForSign,
                      },
                      { title: 'Print', disabled: true, action: _vm.printContract },
                      {
                        title: 'Download',
                        disabled: true,
                        action: function () { return _vm.downloadFile(_vm.pdfFile); },
                      } ]}}):_c('px-dropdown',{attrs:{"title":"Actions","btn-classes":"btn btn-sm btn-primary dropdown-toggle","is-icon-hidden":true,"items":_vm.emptyPrice
                  ? [
                      { title: 'Share', action: _vm.openModal },
                      {
                        title: 'Generate PDF',
                        disabled: _vm.isPdfInProcess,
                        inProcess: _vm.isPdfInProcess,
                        action: _vm.makeAndDownloadPdf,
                      },
                      { title: 'Print', disabled: true, action: _vm.printContract },
                      {
                        title: 'Download',
                        disabled: true,
                        action: function () { return _vm.downloadFile(_vm.pdfFile); },
                      } ]
                  : [
                      { title: 'Share', action: _vm.openModal },
                      {
                        title: 'Generate PDF',
                        disabled: _vm.isPdfInProcess,
                        inProcess: _vm.isPdfInProcess,
                        action: _vm.makeAndDownloadPdf,
                      },
                      {
                        title: _vm.sentToSign ? 'Resend for Sign' : 'Send for Sign',
                        disabled: _vm.subTotal <= 0 || _vm.isPdfAndSendForSignInProcess || _vm.status === 'Signed',
                        inProcess: _vm.isPdfAndSendForSignInProcess,
                        action: _vm.sendForSign,
                      },
                      { title: 'Print', disabled: true, action: _vm.printContract },
                      {
                        title: 'Download',
                        disabled: true,
                        action: function () { return _vm.downloadFile(_vm.pdfFile); },
                      } ]}})],1)]},proxy:true}])})],1),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md"},[_c('div',{staticClass:"d-flex flex-stack mt-8 pt-8 border-top"},[_c('div',{staticClass:"me-4"},[_c('px-btn',{attrs:{"color":"secondary","in-process":_vm.prevInProcess,"disabled":_vm.prevInProcess},nativeOn:{"click":function($event){return _vm.goToPrev.apply(null, arguments)}}},[_vm._v(" Back ")])],1),_c('div',{staticClass:"ms-auto"},[_c('px-btn',{attrs:{"extended-classes":"me-2","in-process":_vm.finishInProcess,"disabled":_vm.finishInProcess},nativeOn:{"click":function($event){return _vm.onFinish.apply(null, arguments)}}},[_vm._v(" Finish ")])],1)])])])]),_c('px-new-modal',{attrs:{"modal-size":"mw-650px","is-visible":_vm.isShareModalOpened,"close-modal":_vm.closeModal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',[_vm._v("Share")])]},proxy:true},{key:"content",fn:function(){return [_c('pxm-share',{attrs:{"item-id":_vm.itemId},on:{"onClose":_vm.closeModal}})]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-auto"},[_c('div',{staticClass:"w-md-300px"})])}]

export { render, staticRenderFns }